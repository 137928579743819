import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import NavLinkList from './nav_link_list'

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <title>{pageTitle ? pageTitle + "｜" + data.site.siteMetadata.title : data.site.siteMetadata.title }</title>
      <nav className='h-16 bg-theme-deep-blue'>
        <NavLinkList></NavLinkList>
      </nav>
      <main className='container mx-auto px-5 md:px-60'>
        {children}
      </main>
    </div>
    )
}

export default Layout
