import * as React from 'react'
import GameGuideItem from './game_guide_item'

const GameGuideList = ({ data }) => {
  return (
    <div className="flex flex-col">
      {
        data.allMdx.nodes.map(guide => (
          <GameGuideItem key={guide.id} guide={guide}></GameGuideItem>
        ))
      }
    </div>
  )
}

export default GameGuideList
