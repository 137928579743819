import * as React from 'react'

const PageTitle = ({ title }) => {
  return (
    <div className='flex justify-center items-center text-center text-2xl md:text-4xl text-gray-500 h-16 md:h-32'>
      {title}
    </div>
  )
}

export default PageTitle
