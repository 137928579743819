import * as React from 'react'
import NavLinkItem from './nav_link_item'

const NavLinkList = () => {
  return (
    <div className='h-full text-center text-lg'>
      <div className='h-full flex flex-col md:flex-row justify-center items-center'>
        <NavLinkItem link_name='遊戲指南' path='/game_guides/1'></NavLinkItem>
      </div>
    </div>
    )
}

export default NavLinkList
