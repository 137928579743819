import * as React from 'react'
import { Link } from 'gatsby'

const NavLinkItem = ({ link_name, path }) => {
  return (
    <div className='h-full text-gray-100 transition hover:text-blue-300 mx-5'>
      <Link className='inline-block flex justify-center items-center w-full h-full my-auto' to={path}>{link_name}</Link>
    </div>
    )
}

export default NavLinkItem
