import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PageTitle from "../components/page_title"
import GameGuideList from "../components/game_guide/game_guide_list"

const IndexPage = ({data}) => {
  return (
    <Layout>
      <PageTitle title="遊戲怪客 GamGeek"></PageTitle>
      <GameGuideList data={data}></GameGuideList>
    </Layout>
    )
}

export const query = graphql`
    query {
      allMdx(sort: {order: DESC, fields: [frontmatter___date]}) {
        nodes {
          slug
          fields {
            slug
          }
          frontmatter {
            buy_link
            buy_link_mobile
            cover_image
            platforms
            sub_title
            title
            date(formatString: "MMMM DD YYYY")
          }
        }
      }
    }
  `

export default IndexPage
