import * as React from 'react'
import { Link } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx'

const GameGuideItem = ({ guide }) => {
  return (
    <div className="mb-5 md:mb-10">
      <Link className='block flex max-h-20 md:max-h-40' key={guide.id} to={`/game_guides/${guide.slug}`}>
        <div className='flex flex-col w-2/3 md:w-3/4'>
          <div className='font-bold text-sm md:text-3xl text-theme-main-color'>{guide.frontmatter.title}</div>
          <div className='text-sm md:text-lg text-theme-secondary-color truncate'>{guide.frontmatter.sub_title}</div>
          <div className='grow flex flex-col justify-end'>
            <span className="text-xs md:text-sm text-theme-secondary-color">{guide.frontmatter.date}</span>
            <span></span>
          </div>
        </div>
        <div className="w-20 md:w-1/4">
          <img className="h-full" src={guide.frontmatter.cover_image}></img>
        </div>
      </Link>
    </div>
  )
}

export default GameGuideItem
